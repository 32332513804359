import axios from "axios";

const url =
  process.env.NODE_ENV === "development"
    ? `http://localhost:8080/prima/dokter`
    : `https://api.persahabatan.co.id/prima/dokter`;

let token = process.env.REACT_APP_API;

const headers = { Authorization: `Persahabatan ${token}` };

export default axios.create({
  baseURL: url,
  timeout: 10000,
  headers: headers
});
